import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://datascience.charlotte.edu/seed-grants-data-science/",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Collaborators and I received a seed grant from UNCC to fund a project related to machine reading for the financial domain (grant # 2021002)`}</a>{`.`}</p>
    <h2 {...{
      "id": "abstract",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#abstract",
        "aria-label": "abstract permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Abstract`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Business decision-makers rely on a causal map between strategy and performance to make
good decisions. However, the causal insights about a business or industry are often fragmented and disconnected across various reporting and research documents. For instance, the International Federation of Accountants estimates the efforts of integrating insights from different reporting documentsfor decision making may have cost the financial industry alone $780 billion annually. Our proposed research seeks to address this problem by `}<strong parentName="p">{`automating the detection and integration of key insights from textual data into a causal knowledge graph for data analytics and decision-making`}</strong>{`. We propose to develop a prototype of machine reading to detect, deconstruct, and integrate causal propositions from scholarly and
reporting texts into a knowledge graph data, showing all the causal pathways among strategy-relevant variables and enterprise performance outcomes. Specifically, we will develop a prototype combining multiple machine-reading models, and train them on a sample of recent SEC documents of S&P financial companies. With an expert from the financial industry, we are developing a proposal for NSF PFI-RP grant due July 14, 2021, as well as pursuing industry sponsorship.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      